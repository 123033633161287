import * as React from "react";
import { graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CInputBtnList, CInputBtnInit, CBtnList02, CHotelMedia
} from "../../components/_index"
import { recruitArea } from "../../utils/recruit"
import { setLang } from "../../utils/set-lang"
import { hotelDatas, regions } from "../../utils/hotels"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const {checked, radio, allCheck, loading} = CInputBtnInit(
    regions.map((item:any) => item.value ),
    ["関東"]
  )

  const inputData = regions.map((item:any) => {
    return {
      value: item.value,
      label: item.label,
      onChange: radio
    }
  })

  const filterData = hotelDatas.filter((item) => checked.includes(item.regions))
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'SEARCH BY DESTINATION',
            ja: 'エリアから探す'
          },
          img: {
            src: '/assets/images/location/kv.png'
          },
          imgSp: {
            src: '/assets/images/location/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: "エリアから探す"
        }
      }} />
      <div className="l_sect03 u_bgGray">
        <LWrap>
          <p className="c_sectLead">北海道から九州まで、全国の主要都市に展開するロイヤルパークホテルズ。<br />
          旅のスタイルに合わせてホテルステイをお愉しみください。</p>
        </LWrap>
      </div>
      <section className="l_sect04">
        <LWrap>
          <h2 className="c_headingLv3 u_tac">エリア一覧</h2>
          <CInputBtnList checkArray={checked} data={inputData} name="regions" type="radio" />
        </LWrap>
      </section>
      <div className="l_sect03 u_bgGray">
        <LWrap>
          { filterData.length ? (
            <CHotelMedia searchCategory="location" data={filterData} />
          ): (
            <p className="u_tac">一致する検索がございません。</p>
          )}
        </LWrap>
      </div>
      <div className="l_sect">
        <LWrap>
          <CBtnList02 data={[
            {
              label: {
                ja: 'ブランドから探す',
                en: 'SEARCH BY BRAND'
              },
              link: {
                href: '/brand/'
              }
            },
            {
              label: {
                ja: '過ごし方から探す',
                en: 'SEARCH BY EXPERIENCE'
              },
              link: {
                href: '/experience/'
              }
            },
          ]} />
        </LWrap>
      </div>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`